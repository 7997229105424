@charset "utf-8";

// Set your brand colors
$blue: #3f88d4;

// Update Bulma's global variables
$info: $blue;

// Update some of Bulma's component variables

// Import only what you need from Bulma
@import '../node_modules/bulma/bulma.sass';

// Add bulma's global custom styles
.container {
  &.is-max-tablet {
    max-width: $tablet;
  }
}

// TODO: Bulmaを最新にアップデート後に削除
.is-justify-content-center.is-justify-content-center {
  justify-content: center;
}
.is-align-items-center.is-align-items-center {
  align-items: center;
}

// ============================
// React toastify
// ============================

@import '../node_modules/react-toastify/dist/ReactToastify.css';

.is-small .Toastify__toast-body {
  padding: 0;
}

// =======================
// Helpers
// =======================
.is-flex-1.is-flex-1 {
  flex: 1;
}
